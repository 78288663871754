<template>
  <div class="pb-32">
    <vs-card>
      <div slot="header" style="display: flex; justify-content: end">
        <vs-button @click="updateOrganization">Save</vs-button>
      </div>
      <div>
        <div class="px-5 font-bold">
          <label> Company Info </label>
        </div>
        <form autocomplete="off">
          <div class="flex">
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <vs-input :success="
                  !errors.first('name') && organizationData.name != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'" name="name"
                  data-vv-as="Organisation Name" label="Organisation Name" icon-no-border icon="icon icon-user"
                  icon-pack="feather" class="w-full" v-model="organizationData.name" />
              </div>
            </div>
            <div class="w-1/2" v-if="$isAuRegion()">
              <div class="w-full px-5 my-5">
                <vs-input :success="!errors.first('abn') && organizationData.abn != ''" val-icon-success="done"
                  val-icon-danger="error" v-validate="'required|max:150'" name="abn" data-vv-as="ABN" label="ABN"
                  icon-no-border icon="icon icon-user" icon-pack="feather" class="w-full"
                  v-model="organizationData.abn" />
              </div>
            </div>
          </div>
        </form>
        <div class="mt-10">
          <div class="px-5 font-bold">
            <label> Account Owner </label>
          </div>
          <OrganisationOwnerDetails />
        </div>
        <div class="mt-10">
          <div class="px-5 font-bold">
            <label> Sage Details </label>
          </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <vs-input
                  loading :success-text="sageSuccess" :success="sageSuccess ? true : false" :danger-text="sageError"
                  :danger="sageError ? true : false" val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="sageClientId" data-vv-as="Sage Client ID" label="Client ID" icon-no-border icon-pack="feather"
                  class="w-full" @input="validateSageCustomer" v-model="organizationData.sageClientId"
                />
              </div>
            </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import OrganisationOwnerDetails from "./organizationOwnerDetails.vue";
import axios from "@/axios.js"

export default {
  components: {
    OrganisationOwnerDetails,
  },
  data: () => ({
    organizationData: {
      name: "",
      abn: "",
      monthTreatmentInvoiceEmail: [],
      consultationFee: 0,
      isMonthlyComplianceEnabled: false,
      sageClientId: "",
    },
    sageError:'',
    sageSuccess:'',
  }),
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationDetails",
      "updateOrganizationDetails",
      "fetchOwnerDetails",
    ]),
    async getOrganizationDetails(id) {
      try {
        this.$vs.loading();
        const res = await this.fetchOrganizationDetails(id);
        this.$vs.loading.close();
        this.organizationData = {
          name: res.data.data.name,
          abn: res.data.data.abn,
          monthTreatmentInvoiceEmail: res.data.data.monthTreatmentInvoiceEmail,
          consultationFee: res.data.data.consultationFee,
          isMonthlyComplianceEnabled: res.data.data.isMonthlyComplianceEnabled,
          isDrugbookEnabled: res.data.data.isDrugbookEnabled,
          isAnalyticsEnabled: res.data.data.isAnalyticsEnabled,
          isInventoryEnabled: res.data.data.isInventoryEnabled,
          isByo:res.data.data.isByo,
          isPaid: res.data.data.isPaid,
          isTestAccount: res.data.data.isTestAccount || false,
          sageClientId: res.data.data.sageClientId,
        };

        this.getSageCustomerInfo()
      } catch (error) {
        console.log(error);
      }
    },
    isEnabled(type) {
      return this.organizationData[type] ? this.organizationData[type] : false;
    },
    async updateOrganization() {
      let isValid = await this.$validator.validate();
      if (isValid) {
        let data = {
          name: this.organizationData.name,
          abn: this.organizationData.abn,
          monthTreatmentInvoiceEmail:
            this.organizationData.monthTreatmentInvoiceEmail,
          consultationFee: this.organizationData.consultationFee
            ? this.organizationData.consultationFee
            : 0,
          isMonthlyComplianceEnabled: this.isEnabled("isMonthlyComplianceEnabled"),
          isDrugbookEnabled: this.isEnabled("isDrugbookEnabled"),
          isAnalyticsEnabled: this.isEnabled("isAnalyticsEnabled"),
          isPaid: this.isEnabled("isPaid"),
          isTestAccount: this.isEnabled("isTestAccount"),
          isInventoryEnabled: this.isEnabled("isInventoryEnabled"),
          sageClientId: this.organizationData.sageClientId,
          isByo: this.isEnabled("isByo"),
        };
        try {
          const res = await this.updateOrganizationDetails({
            data: data,
            id: this.$route.params.organizationId,
          });
          this.$vs.notify({
            title: "Details Updated",
            text: "Organisation Details successfully updated.",
            color: "success",
          });
          this.getOrganizationDetails(this.$route.params.organizationId);
        } catch (err) {
          isValid = false;
          if (err.status === 422) {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update details. Try again!",
              color: "danger",
            });
          }
          if (err.status === 409) {
            this.$vs.notify({
              title: "Failed",
              text: err.data.message,
              color: "danger",
            });
            this.errors.add({
              field: "email",
              msg: err.data.message,
            });
          }
        }
      }
    },
    async getSageCustomerInfo() {
        try {
            if (!this.organizationData.sageClientId) {
                this.sageSuccess = ''
                this.sageError = ''
                return
            }

            this.$vs.loading();

            const {data} = await axios.get(`/sage/customer/${this.organizationData.sageClientId}`)

            if (data && data.data && data.data.NAME) {
                this.sageSuccess = `Customer ID ${this.organizationData.sageClientId} corresponds to an entity named ${data.data.NAME} with status ${data.data.STATUS} on the Sage platform.`
                this.sageError = ''
            } else {
                this.sageSuccess = ''
                this.sageError = `The customer with ID ${this.organizationData.sageClientId} does not exist on the Sage platform.`
            }
        } catch (e) {
            this.sageSuccess = ''
            this.sageError = `The customer with ID ${this.organizationData.sageClientId} does not exist on the Sage platform.`
        } finally {
            this.$vs.loading.close();
        }
    },
    async validateSageCustomer() {
        if (this.timeout)
            clearTimeout(this.timeout);

        this.timeout = setTimeout(async () => {
            this.getSageCustomerInfo()
        }, 1000); // delay
    },
  },
  async created() {
    this.getOrganizationDetails(this.$route.params.organizationId);
  },
};
</script>
