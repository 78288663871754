<template>
  <div>
    <div>
      <form autocomplete="off">
        <div class="flex">
          <div class="w-1/2">
            <div class="w-full px-5 my-5">
              <vs-input
                :disabled="true"
                :success="
                  !errors.first('firstName') && orgOwnerData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="First Name"
                label="First Name"
                class="w-full"
                v-model="orgOwnerData.firstName"
              />
            </div>
          </div>
          <div class="w-1/2">
            <div class="w-full px-5 my-5">
              <vs-input
                :disabled="true"
                :success="!errors.first('email') && orgOwnerData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="email"
                data-vv-as="Account Email"
                label="Account Email"
                class="w-full"
                v-model="orgOwnerData.email"
              />
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/2">
            <div class="w-full px-5 my-5">
              <vs-input
                :disabled="true"
                :success="
                  !errors.first('lastName') && orgOwnerData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="Last Name"
                label="Last Name"
                class="w-full"
                v-model="orgOwnerData.lastName"
              />
            </div>
          </div>
          <div class="w-1/2 flex items-center px-5 pt-3">
            <vs-button type="border" @click="viewOrgOwner" v-if="orgOwnerData._id">View</vs-button>
          </div>
        </div>
      </form>
    </div>
    <assign-owner
      :hasOwner="orgOwnerData.email.length !== 0 ? true : false"
      @onSuccess="getOwnerDetails"
    />
  </div>
</template>


<script>
import { mapActions } from "vuex";
import AssignOwner from "./assignOwner.vue";

export default {
  components: {
    AssignOwner,
  },
  props: {
    basicEditRoute: {
      type: String,
      default: "SuperAdminOrganizationOwnerDetails",
    },
  },
  data: () => ({
    orgOwnerData: {
      firstName: "",
      email: "",
      lastName: "",
      _id: "",
    },
  }),
  methods: {
    ...mapActions("organization", ["fetchOwnerDetails"]),

    async getOwnerDetails() {
      const res = await this.fetchOwnerDetails({ id: this.organizationId });
      if (res.data.data.length !== 0) {
        const data = res.data.data[0];
        this.orgOwnerData = {
          firstName: data.firstName,
          email: data.email,
          lastName: data.lastName,
          _id: data._id,
        };
      }
      this.serverResponded = true;
    },

    viewOrgOwner() {
      this.$router.push({
        name: this.basicEditRoute,
        params: {
          basicUserId: this.orgOwnerData._id,
        },
      });
    },
  },
  async created() {
    this.organizationId = this.$route.params.organizationId;
    this.getOwnerDetails();
  },
};
</script>