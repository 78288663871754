<template>
  <div class="pb-32">
    <vs-card>
      <div slot="header" class="flex justify-between items-center">
        <h4> Organisation Settings</h4>
        <vs-button @click="updateOrganization">Save</vs-button>
      </div>
      <div>
        <div class="px-5">
          <p>
            Organisation settings will act as the default settings for all clinics created unless updated manually.
          </p>
        </div>
        <form autocomplete="off">
          <div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <vs-input :success="
                  !errors.first('monthTreatmentInvoiceEmail') &&
                  organizationData.monthTreatmentInvoiceEmail != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="monthTreatmentInvoiceEmail" data-vv-as="Monthly Treatment Invoice Email(s)"
                  label="Monthly Treatment Invoice Email" class="w-full"
                  v-model="organizationData.monthTreatmentInvoiceEmail" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <vs-input :success="
                  !errors.first('consultationFee') &&
                  organizationData.consultationFee != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="consultationFee" data-vv-as="Treatment Consultation Fee(Default is $25)"
                  label="Treatment Consultation Fee(Default is $25)" class="w-full"
                  v-model="organizationData.consultationFee" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <vs-input :success="
                  !errors.first('closedLoopConsultationFee') &&
                  organizationData.closedLoopConsultationFee != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="closedLoopConsultationFee" data-vv-as="closedLoopConsultationFee"
                  label="Closed looped doctors Fee" type="number" class="w-full"
                  v-model="organizationData.closedLoopConsultationFee" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Monthly Compliance</label>
                <vs-switch :success="
                  !errors.first('isMonthlyComplianceEnabled') &&
                  organizationData.isMonthlyComplianceEnabled != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="consultationFee" data-vv-as="Enable Monthly Compliance" label="Enable/Disable Monthly Compliance"
                  v-model="organizationData.isMonthlyComplianceEnabled" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Drugbook</label>
                <vs-switch :success="
                  !errors.first('isDrugbookEnabled') &&
                  organizationData.isDrugbookEnabled != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="consultationFee" data-vv-as="Enable Monthly Compliance" label="Enable/Disable Drugbook"
                  v-model="organizationData.isDrugbookEnabled" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Insights </label>
                <vs-switch :success="
                  !errors.first('isAnalyticsEnabled') &&
                  organizationData.isAnalyticsEnabled != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'" name="analytics"
                  data-vv-as="Enable Analytics" label="Enable/Disable Insights"
                  v-model="organizationData.isAnalyticsEnabled" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Inventory </label>
                <vs-switch :success="
                  !errors.first('isInventoryEnabled') &&
                  organizationData.isInventoryEnabled != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'" name="analytics"
                  data-vv-as="Enable Analytics" label="Enable/Disable Insights"
                  v-model="organizationData.isInventoryEnabled" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Paid Version</label>
                <vs-switch :success="
                  !errors.first('isPaid') &&
                  organizationData.isPaid != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'"
                  name="consultationFee" data-vv-as="Enable Monthly Compliance" label="Enable/Disable Paid Version"
                  v-model="organizationData.isPaid" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Test Account</label>
                <vs-switch :success="
                  !errors.first('isPaid') &&
                  organizationData.isTestAccount != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'" name="isTestAccount"
                  data-vv-as="Test Account" label="Enable/Disable Test Account"
                  v-model="organizationData.isTestAccount" />
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable Skip Monthly Payment</label>
                <vs-switch
                  name="isSkipMonthlyPayment"
                  label="Enable/Disable Skip Monthly Payment"
                  v-model="organizationData.isSkipMonthlyPayment"/>
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full px-5 my-5">
                <label>Enable/Disable BYO Organization</label>
                <vs-switch :success="
                  !errors.first('isBYO') &&
                  organizationData.isByo != ''
                " val-icon-success="done" val-icon-danger="error" v-validate="'required|max:150'" name="isByo"
                  data-vv-as="BYO Organization" label="Enable/Disable BYO Organization"
                  v-model="organizationData.isByo" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </vs-card>
    <vs-popup class="holamundo" title="Confirmation required" :active.sync="showModal">
      <p>Are you sure you want to turn Drugbook off? There are {{ clinics.length }} clinics within your organisation
        currently using Drugbook.</p>
      <ul style="list-style:disc;margin-left:30px;margin-top: 10px;">
        <li v-for="(name, index) in clinics" v-bind:key="index">{{ name }}</li>
      </ul>
      <vs-divider></vs-divider>
      <vs-row vs-align="space-between" vs-justify="flex-end" vs-type="flex" vs-w="12">
        <vs-col vs-w="3" style="display:block;text-align:right">
          <vs-button style="padding:5px 15px;margin-right:5px;" @click="confirm()">Confirm</vs-button>
        </vs-col>
        <vs-col vs-w="2" style="display:block;text-align:right">
          <vs-button style="padding:4px 15px;" @click="cancel()" type="border">Cancel</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      organizationData: {
        name: "",
        abn: "",
        monthTreatmentInvoiceEmail: "",
        consultationFee: "",
        closedLoopConsultationFee: 0,
        isMonthlyComplianceEnabled: false,
        isDrugbookEnabled: false,
        isByo: false,
        isAnalyticsEnabled: false,
        isInventoryEnabled: false,
        isPaid: false,
        isTestAccount: false
      },
      showModal: false,
      isConfirmed: false,
      clinics: []
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationDetails",
      "updateOrganizationDetails",
    ]),

    confirm() {
      this.showModal = false;
      this.isConfirmed = true;
    },
    cancel() {
      this.showModal = false;
      this.isConfirmed = false;
      this.organizationData.isDrugbookEnabled = true
    },

    async getOrganizationSettingsDetails(id) {
      this.$vs.loading();
      const res = await this.fetchOrganizationDetails(id);

      if (res.data.data) {
        this.organizationData = {
          name: res.data.data.name,
          abn: res.data.data.abn,
          consultationFee: res.data.data.consultationFee,
          closedLoopConsultationFee: res.data.data.closedLoopConsultationFee || 0,
          monthTreatmentInvoiceEmail: res.data.data.monthTreatmentInvoiceEmail,
          isMonthlyComplianceEnabled: res.data.data.isMonthlyComplianceEnabled,
          isSkipMonthlyPayment: res.data.data.isSkipMonthlyPayment || false,
          isDrugbookEnabled: res.data.data.isDrugbookEnabled,
          isByo: res.data.data.isByo,
          isAnalyticsEnabled: res.data.data.isAnalyticsEnabled,
          isInventoryEnabled: res.data.data.isInventoryEnabled,
          isPaid: res.data.data.isPaid || false,
          isTestAccount: res.data.data.isTestAccount || false,
        };
        this.clinics = res.data.data.clinicorg.map((val) => val.clinic.clinicName);
        this.$vs.loading.close();
      }
    },
    isEnabled(type) {
      return this.organizationData[type] ? this.organizationData[type] : false;
    },
    async updateOrganization() {
      try {
        let data = {
          // monthTreatmentInvoiceEmail:
          //   this.organizationData.monthTreatmentInvoiceEmail === ""
          //     ? []
          //     : this.organizationData.monthTreatmentInvoiceEmail.split(","),
          consultationFee: this.organizationData.consultationFee,
          closedLoopConsultationFee: this.organizationData.closedLoopConsultationFee || 0,
          monthTreatmentInvoiceEmail:
            this.organizationData.monthTreatmentInvoiceEmail,
          name: this.organizationData.name,
          abn: this.organizationData.abn,
          isMonthlyComplianceEnabled: this.isEnabled("isMonthlyComplianceEnabled"),
          isDrugbookEnabled: this.isEnabled("isDrugbookEnabled"),
          isByo: this.isEnabled("isByo"),
          isAnalyticsEnabled: this.organizationData.isAnalyticsEnabled || false,
          isInventoryEnabled: this.organizationData.isInventoryEnabled || false,
          isSkipMonthlyPayment: !!this.organizationData.isSkipMonthlyPayment,
          isPaid: this.isEnabled("isPaid"),
          isTestAccount: this.isEnabled("isTestAccount"),
        };
        const updateRes = await this.updateOrganizationDetails({
          data: data,
          id: this.$route.params.organizationId,
        });
        this.$vs.notify({
          title: "Details Updated",
          text: "Organisation Details successfully updated.",
          color: "success",
        });
      } catch (err) {
        if (err.status === 422) {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update details. Try again!",
            color: "danger",
          });
        }
        if (err.status === 409) {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.errors.add({
            field: "email",
            msg: err.data.message,
          });
        }
      }
    },
  },
  created() {
    this.getOrganizationSettingsDetails(this.$route.params.organizationId);
  },
  watch: {
    "organizationData.isDrugbookEnabled": function (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.showModal = true;
      }
    },
  },
};
</script>
