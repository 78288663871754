<template>
  <vs-card>
    <div
      slot:header
      class="flex flex-no-wrap w-full justify-end items-center"
      vs-w="12"
    >
      <div class="mr-2">

        <vs-button @click="redirectToCreateClinic">Create</vs-button>
      </div>
      <div class="mr-2">
      </div>
    </div>
    <div style="padding-left: 10px">
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
            <!-- <div class="pl-1" style="width: 14.5rem">
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select>
          </div> -->
            <div class="w-24 pl-1">
              <vs-select
                width="100%"
                placeholder="10"
                autocomplete
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text relative"
                style="border: 1px solid #e8e8e8"
              >
                <div class="flex">
                  <h4 class="mr-2 mobile-text">
                    {{
                      users[indextr].clinicName
                        ? users[indextr].clinicName
                        : "N/A" | capitalize
                    }}
                  </h4>
                  <!-- <br />
                  <h4 class="mobile-text">
                    {{
                      users[indextr].role
                        ? users[indextr].address.displayAddress 
                        : "N/A" | capitalize
                    }}
                  </h4> -->
                </div>
                <div>
                  <p>
                    {{
                      (users[indextr].address && users[indextr].address.displayAddress) ? users[indextr].address.displayAddress : 'N/A'
                    }}
                  </p>
                </div>
                <div class="absolute top-0 right-0">
                  <vs-button
                    style="float: left"
                    size="small"
                    type="border"
                    @click="redirectToClinicEdit(users[indextr]._id)"
                    class="m-1"
                  >
                    Edit</vs-button
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <!-- <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
        
          <div>
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select>
          </div>
        </div> -->

          <template slot="thead">
            <vs-th sort-key="clinicName">Clinic Name</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].clinicName">{{
                data[indextr].clinicName || "N/A" | capitalize
              }}</vs-td>
              <vs-td :data="(data[indextr].address && data[indextr].address.displayAddress) ? data[indextr].address.displayAddress : 'N/A'">{{
                (data[indextr].address && data[indextr].address.displayAddress) ? data[indextr].address.displayAddress : 'N/A'
              }}</vs-td>
              <!-- <vs-td :data="data[indextr].role">{{ data[indextr].role }}</vs-td> -->
              <vs-td :data="data[indextr]._id">
                <vs-button
                  style="float: left"
                  size="small"
                  type="border"
                  @click="redirectToClinicEdit(data[indextr]._id)"
                  class="m-1"
                >
                  Edit</vs-button
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "organization-table",
  components: {},
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminNurseDetailView",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: false,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        id: "",
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Approved", label: "Approved" },
        { value: "Unapproved", label: "Unapproved" },
      ],
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("organization", ["fetchOrganizationClinicsList"]),
    search(searching) {
      this.searchInputString = searching;
    },
    moment(date) {
      return moment(date);
    },
    async getOrganizationClinicsList() {
      this.$vs.loading();
      if (this.dataTableParams.status == "all")
        this.dataTableParams.status = "";
      try {
        const res = await this.fetchOrganizationClinicsList(
          this.dataTableParams
        );
        this.$vs.loading.close();
        this.users = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
        this.noDataText = "No clinics available";
      } catch (err) {
        console.log("This is the error ::", err);
      }
    },
    // getOrganizationClinics(id) {
    //   this.$vs.loading();
    //   this.fetchOrganizationClinics({ id: id }).then((res) => {
    //     this.$vs.loading.close();
    //     console.log(res);
    //     this.noDataText = "No Clinics Available";
    //   });
    // },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrganizationClinicsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getOrganizationClinicsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getOrganizationClinicsList();
    },
    redirectToCreateClinic() {
      this.$router.push({
        path: `/super-admin/organizations/${this.dataTableParams.id}/clinics/create`,
      });
    },
    redirectToClinicEdit(id) {
      // this.$router.go(-1)
      this.$router.push({
        path: `/super-admin/organizations/${this.dataTableParams.id}/clinics/${id}/edit`,
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    nurseDetails: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getOrganizationClinicsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getOrganizationClinicsList();
      }
    },
    // "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
    //   if (newfilterVal !== oldFilterVal) {
    //     this.serverResponded = false;
    //     this.dataTableParams.page = 1;
    //     this.dataTableParams.filter = newfilterVal;
    //     // this.getNursesList();
    //   }
    // },
    // "$store.state.AppActiveClinicId": function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.dataTableParams.activeClinicId = newVal;
    //     // this.getNursesList();
    //   }
    // },
  },
  async created() {
    // this.dataTableParams.activeClinicId = await this.$store.state
    //   .AppActiveClinicId;
    // if (this.franchiseId && this.franchiseId !== "") {
    //   this.dataTableParams.franchiseId = this.franchiseId;
    // }
    // this.getNursesList();
    this.dataTableParams.id = this.$route.params.organizationId;
    this.getOrganizationClinicsList();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
}
</style>
