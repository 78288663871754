<template>
  <vx-card style="padding-left: 10px">
    <div id="compliance-list-table" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="monthlyCompliances"
        search
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
      >
        <div slot="header" class="mb-2 flex flex-wrap">
          <div class="pr-2 w-1/4">
            <vs-select
              class="select"
              autocomplete
              v-model="selectedStatus"
              width="100%"
              id="compliance-list-status"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in statusList"
              />
            </vs-select>
          </div>
          <div class="pr-2 w-1/4">
            <vs-select
              class="select"
              :placeholder="currentMonth"
              autocomplete
              v-model="selectedMonth"
              width="100%"
              id="compliance-list-month"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in monthDropdown"
              />
            </vs-select>
          </div>
          <div class="pr-2 w-1/4">
            <vs-select
              class="select"
              :placeholder="currentYear"
              autocomplete
              v-model="selectedYear"
              width="100%"
              id="compliance-list-year"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in yearDropdown"
              />
            </vs-select>
          </div>
          <div class="pr-2 w-1/4">
            <vs-select
              placeholder="All Clinics"
              width="100%"
              id="compliance-list-clinic"
              autocomplete
              v-model="dataTableParams.clinic"
            >
              <vs-select-item
                :key="index"
                :value="item.clinicId"
                :text="item.name"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="clinicName">CLINIC NAME</vs-th>
          <vs-th sort-key="quarter">QUARTER</vs-th>
          <vs-th sort-key="orderYear">YEAR</vs-th>
          <vs-th sort-key="status">STATUS</vs-th>
          <vs-th>ACTIONS</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in monthlyCompliances"
          >
            <vs-td :data="data[indextr].clinicName">{{
              data[indextr].clinicName
            }}</vs-td>
            <vs-td :data="data[indextr].month">{{
              getQuarter(data[indextr].quarter)
            }}</vs-td>
            <vs-td :data="data[indextr].year">{{
              data[indextr].orderYear
            }}</vs-td>
            <vs-td :data="data[indextr].status">
              <div :class="data[indextr].status.toLowerCase() + ' rounded-full text-sm text-center px-3 py-1 inline-block font-semibold'">
                {{getStatusData(data[indextr].status)}}
              </div>
            </vs-td>
            <vs-td :data="data[indextr]._id">
                <!-- v-if="data[indextr].status == 'upNext'" -->
              <vs-button
                size="small"
                icon-pack="feather"
                class="mr-2"
                @click="startComplianceData(data[indextr]._id)"
                >Start</vs-button
              >
              <!-- <vs-button
                v-else-if="data[indextr].status == 'inReview'"
                size="small"
                type="border"
                icon-pack="feather"
                class="mr-2"
                @click="viewComplianceData(data[indextr]._id)"
                >View</vs-button
              > -->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  inject: ['getRoleRouteName'],
  data() {
    return {
      limitOptions: [5, 10, 25, 50, 100],
      noDataText: "Loading..",
      yearDropdown: [],
      serverResponded: false,
      currentYear: new Date().getFullYear(),
      totalDocs: 0,
      dataTableParams: {
        organizationId: "",
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: null,
        clinic: null,
      },
      monthDropdown: [
        { text: "Q1 (Jan - Mar)", value: "1" },
        { text: "Q2 (Apr - Jun)", value: "2" },
        { text: "Q3 (Jul - Sep)", value: "3" },
        { text: "Q4 (Oct - Dec)", value: "4" },
      ],
      status: [
        {text: "Up Next", value: "upNext"},
        {text: "Overdue", value: "overdue"},
        {text: "In Review", value: "inReview"},
        {text: "Done", value: "done"},
        {text: "All", value: "all"},
      ],
      statusList: [
        { text: "All", value: "all" },
        { text: "Up next", value: "upNext" },
        { text: "Ready for review", value: "inReview" },
        { text: "Completed", value: "completed" },
        { text: "Skipped", value: "skipped" },
        { text: "Missed", value: "failed" },
        { text: "Change Requested", value: "changeRequested" },
      ],
      selectedStatus: "all",
      currentMonth: "",
      weekArray: [],
      monthlyCompliances: [],
      firstWeekData: "",
      selectedMonth: "",
      selectedYear: new Date().getFullYear(),
      clinics: [{ clinicId: null, name: "All Clinics" }],
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("monthlyCompliance", [
      "fetchOrganisationComplianceList",
      "startCompliance",
    ]),
    ...mapActions("organization", ["fetchOrganizationAllClinics"]),
    getQuarter(month) {
      return _.filter(this.monthDropdown, ["value", month])[0].text;
    },
    getYearDropdown() {
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    },
    getStatus(status) {
      return _.find(this.statusList, ["value", status]).text;
    },
    async startComplianceData(id) {
      this.$router.push({
        name: "superAdminComplianceReview",
        params: { clinicId: id },
      });
    },
    async viewComplianceData(id) {
      this.$router.push({
        name: this.getRoleRouteName("ComplianceReview"),
        params: { clinicId: id },
      });
    },
    getOrganizationAllClinicsList(id) {
      this.fetchOrganizationAllClinics(
        this.$route.params.organizationId
      ).then((res) => {
        this.$vs.loading.close();
        this.clinics = [
          { clinicId: null, name: "All Clinics" },
          ...res.data.data,
        ];
      });
    },
    getMonthlyComplianceList() {
      this.$vs.loading();
      this.fetchOrganisationComplianceList({
        ...this.dataTableParams,
        status: this.selectedStatus === "all" ? null : this.selectedStatus,
        month: this.selectedMonth,
        year: this.selectedYear,
        orgId: this.$route.params.organizationId
      })
        .then((res) => {
          this.$vs.loading.close();
          this.noDataText = "No Quarterly Compliance Found";
          this.serverResponded = true;
          this.monthlyCompliances = res.data.data.docs;
          this.totalDocs =
            res.data.data.pagination.total || res.data.data.pagination[0].total;
          this.page =
            res.data.data.pagination.page || res.data.data.pagination[0].page;
          this.currentPage =
            res.data.data.pagination.page || res.data.data.pagination[0].page;
        })
        .catch((err) => {
          console.log(err)
          this.noDataText = "No Quarterly Compliance Found";
          this.$vs.loading.close();
        });
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getMonthlyComplianceList();
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getMonthlyComplianceList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getMonthlyComplianceList();
    },
    getStatusData(data){
      const status = {
        upNext: 'Up Next',
        failed: 'Missed',
        skipped: 'Skipped',
        inReview: 'Ready for Review',
        changeRequested: 'Change Requested',
        completed: 'Completed',
      }

      return status[data];
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    selectedMonth: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedMonth = newVal;
        this.getMonthlyComplianceList();
      }
    },
    selectedYear: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedYear = newVal;
        this.getMonthlyComplianceList();
      }
    },
    selectedStatus: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedStatus = newVal;
        this.getMonthlyComplianceList();
      }
    },
    "dataTableParams.clinic": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.clinic = newVal;
        this.getMonthlyComplianceList();
      }
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getMonthlyComplianceList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getMonthlyComplianceList();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getMonthlyComplianceList();
      }
    },
  },
  created() {
    const currentMonthObj = this.monthDropdown[moment(new Date()).quarter() - 1];
    this.dataTableParams.organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.currentMonth = currentMonthObj.text;
    this.selectedMonth = currentMonthObj.value;
    this.getYearDropdown();
    this.getMonthlyComplianceList();
    this.getOrganizationAllClinicsList();
  },
};
</script>

<style scoped>
.select {
  width: 65px;
  margin-right: 5px;
}
.selectClinic {
  width: 120px;
  margin-right: 5px;
}
.week-select {
  width: 195px;
  margin-right: 5px;
}
td {
  font-size: 15px !important;
  white-space: nowrap !important;
}

.closeTd {
  display: none;
}

.openTd {
  display: block;
}

.detailText {
  cursor: pointer;
  color: blue;
}

.coloredTr {
  background: aliceblue;
}

.weeklyViewButton {
  border: 1px solid;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  cursor: pointer;
  border-color: #aaa !important;
  color: #666 !important;
  box-shadow: 0 1px 3px #eee;
}

.monthlyViewButton {
  border: 1px solid;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  border-color: #aaa !important;
  color: #666 !important;
  box-shadow: 0 1px 3px #eee;
}

.selectedMonthView {
  background: #aaa;
  color: #fff;
}

.selectedView {
  background: #aaa;
  color: #fff;
}

.selectedView .svgIcon {
  color: #fff;
}
.upnext, .inreview {
  background-color: #E5F8EE;
  color: #84CDB8;
}
.skipped {
  background-color: #E2E8E6;
  color: #074230;
}
.failed {
  background-color: #FBE4E4;
  color: #EA5455;
}
.changerequested {
  background-color: #FF9F43;
  color: #FFF2E3;
}
.completed {
  background-color: #E2E8E6;
  color: #074230;
}
</style>
