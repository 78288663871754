<template>
  <div>
    <vs-tabs class="organization-details">
      <vs-tab label="Organisation Details">
        <div><OrganizationDetails /></div>
      </vs-tab>
      <vs-tab label="Clinics">
        <div><OrganizationClinics :canAssignClinic="true" /></div>
      </vs-tab>
      <vs-tab label="Users & Accounts">
        <div>
          <OrganizationAccountsAndUsers :canAssignClinic="false" />
          <!-- <franchise-associate-doctor
            :canDelete="true"
            viewRoute="SuperAdminDoctorEdit"
            viewDetailRoute="SuperAdminDoctorDetail"
            :canCreate="true"
            createRoute="franchise-management-doctors-create"
            :franchiseId="franchiseId"
            v-if="franchiseId"
            :canRequestAssociation="true"
          /> -->
        </div>
      </vs-tab>
      <vs-tab label="Treatments">
        <div><OrganizationTreatments /></div>
      </vs-tab>
      <vs-tab label="Invoices">
        <div>
          <Invoices :clinicId="'all'" />
        </div>
      </vs-tab>
      <vs-tab label="Orders">
        <div>
          <!-- <OrdersList
            :orderData="orderData"
            :totalDocs="totalDocs"
            :page="page"
            :noDataText="noDataText"
            @fetchList="getOrdersList"
            @viewOrderDetail="viewOrderDetail"
            @regenerateAttachment="generatePDF"
          /> -->
          <OganizationOrders />
        </div>
      </vs-tab>
      <vs-tab label="Compliance">
        <monthlyComplianceList/>
      </vs-tab>
      <vs-tab label="Settings">
        <div><OrganizationSettings /></div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OrganizationDetails from "../../components/organization/organizationDetails.vue";
import OrganizationAccountsAndUsers from "../../organization/users/organizationAccountsAndUsers.vue";
import OrganizationTreatments from "../../organization/treatments/organizationTreatments.vue";
import OrganizationClinics from "../../organization/clinic/organizationClinics.vue";
import OganizationOrders from "../../organization/orders/oganizationOrders.vue";
import Invoices from "../../organization/invoices/List.vue";
import OrganizationSettings from "../../organization/settings/organizationSettings.vue";
import monthlyComplianceList from "../../organization/monthly-compliance/complianceList.vue";
import OrdersList from "../../components/WebStore/orders/OrdersList";

export default {
  components: {
    OrganizationDetails,
    OrganizationAccountsAndUsers,
    OrganizationClinics,
    Invoices,
    OganizationOrders,
    OrganizationTreatments,
    OrganizationSettings,
    monthlyComplianceList,
    OrdersList
  },
   data() {
    return {
      // totalDocs: 0,
      // page: 1,
      // orderData: [],
      // noDataText: "Loading...",
    };
  },
  methods: {
    // ...mapActions("storeOrder", ["getOrderList", "regenerateAttachment"]),
    // async getOrdersList(dataTableParams) {
    //   let self = this;
    //   this.getOrderList(dataTableParams).then(async (res) => {
    //     self.orderData = res.data.data.docs;
    //     self.totalDocs = res.data.data.pagination.total || 0;
    //     self.page = res.data.data.pagination.page;
    //     self.currentPage = res.data.data.pagination.page;
    //     self.noDataText = "No Orders Available";
    //   });
    // },
    // viewOrderDetail(id) {
    //   this.$router.push({
    //     name: "superAdminStoreViewOrder",
    //     params: { orderId: id },
    //   });
    // },
    // createOrderHandler() {
    //   this.$router.push({ name: "superAdminStoreCreateOrder" });
    // },
    // async generatePDF(id) {
    //   this.$vs.loading();
    //   this.regenerateAttachment(id).then((res) => {
    //     this.$vs.loading.close();
    //     if (res.status === 200) {
    //       this.$vs.notify({
    //         title: "Invoice PDF generated succesfully",
    //         text: "Please reload the page",
    //         color: "success",
    //         iconPack: "feather",
    //         icon: "icon-alert-circle",
    //       });
    //     }
    //   });
    // },
  },
};
</script>
